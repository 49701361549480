// react 
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './table.scss';
// ..........

// package components
import type { CalendarProps } from 'antd';
import { Badge, Button, Calendar, Checkbox, Input, Modal, Spin, TableProps, Tag, Tooltip, message } from 'antd';
import Table from 'antd/es/table';
import { Dayjs } from 'dayjs';
import dayjs from "dayjs";
import moment from 'moment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Pagination } from 'antd';
// ..........

// child components
import { useGlobalState } from '../../Statecontext';
import { Utils } from '../../core/utils/utils';
import { downloadServices } from '../../core/services/download';
// ..........

// icons.............
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableViewIcon from '@mui/icons-material/TableView';
import uploadIcon from '../../assets/images/Vector.svg';
import downloadIcon from '../../assets/images/dowload-icon.svg';
import EastIcon from '@mui/icons-material/East';
import expandIcon from '../../assets/images/expandIcon.svg';
import expandIcon1 from '../../assets/images/expandIcon2.svg';
import plusIcon from '../../assets/images/plus.svg';
import searchIcon from '../../assets/images/search.svg';
import synctheme from '../../assets/images/sync__all.svg';
import syncWhite from '../../assets/images/sync_now.svg';
import viewSync from '../../assets/images/view_sync.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import deleteIcon from '../../assets/images/deleteIcon.svg'
import DoneIcon from '@mui/icons-material/Done';
import AutorenewIcon from '@mui/icons-material/Autorenew';
// ..........

// table props types
interface MyComponentProps {
    label: string;
    expand: boolean;
    button: Array<{
        type: string;
        name: string;
        theme: string;
        conditions?: any;
        downloadquery?: string;
        function?: (args?: any) => any;
    }>;
    columns: any;
    hideSelect?: boolean;
    ref?: any;
    tabledata?: any;
    loading?: boolean;
    totalpages?: any;
    duedate?: any;
    gstdata?: any;
    syncprogress?: any;
    syncedCountsData?: any;
    chartclick?: any;
    handleDelete?: () => void;
}
// ..........

export const ZTable2: React.FC<MyComponentProps> = forwardRef((props, ref) => {
    const { handleDelete } = props;

    // form values states
    const [userRole, setUserRole] = useState<any>()
    const [noticeObj, setNoticeObj] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(undefined);
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [messageApi, contextHolder] = message.useMessage();
    var [tableHeader, setTableHeader] = useState<any>()
    // ..........

    // loading state
    // ..........

    // limit & filter states
    const [filter, setFilter] = useState(false)
    const [filterList, setFilterList] = useState<any>([])
    const [secStateFilter, setSecStateFilter] = useState<any>({ state: [], arn_nos: [], case_ids: [], section: [] })
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [secFilterCase, setSecFilterCase] = useState('')
    const [checkedArnItems, setCheckedArnItems] = useState<string[]>([])
    const [checkedCaseIdItems, setCheckedCaseIdItems] = useState<string[]>([])
    const [checkedSessionItems, setCheckedSessionItems] = useState<string[]>([])
    const [secondaryFilter, setSecondaryFilter] = useState<any>([])
    const { globalsearch, subselectgst, selectgst, tableKey, setTableKey, expanded, setExpanded, downloading, setDownloading, filterQuery, setFilterQuery, sorterQuery, setSorterQuery, searchquery, setSearchquery, noticeQuery, setNoticeQuery, chartquery, setChartquery, page, setPage, viewnoticeacitve, setViewnoticeactive, tabactive, setTabactive, manageactive, setManageactive, tabview, setTabview, sumpopup, setSumpopup, setSumdrawer, drawerloading, rowsPerPage, setRowsPerPage, setStatuschange, assignpopup, setAssignpopup, selectedGstRows, setSelectedGstRows, setAddnotice, setreportModal, setNoticedata, changeview, setChangeview } = useGlobalState();
    // ..........

    //  datas states
    const [noticeData, setNoticeData] = useState<any>([])
    const [selectedGST, setSelectedGST] = useState<any>([])
    const [selectedRowsArray, setSelectedRowsArray] = useState<any>([])
    const [buttondetails, setButtonDetails] = useState<any>([])
    const [showsearch, setShowsearch] = useState(false)
    const [localsearch, setLocalsearch] = useState('')
    // ..........

    // reference states
    const { ticket } = Utils();
    const tableListData = useRef(false)
    let location = useLocation();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const formattedDate = dayjs(selectgst && selectgst.last_sync).format("DD-MM-YY, h:mm A");
    // ..........

    useEffect(() => {
        setUserRole(localStorage.getItem('userRole') || '')
        setFilterList(props.tabledata)
        setNoticeData(props.tabledata)
        setSelectedGST([])
        setSelectedRowsArray([])
    }, [props.tabledata])


    useEffect(() => {
        if (props.label === 'Table of Contents') {
            setButtonDetails(props.button)
            if (tabactive === 'Manual') {
                setButtonDetails(buttondetails.filter((button: any) => button.type !== 'status'));
                const exists = buttondetails.some((button: any) => button.type === addnotice.type);
                if (!exists) {
                    setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, addnotice]);
                }
            }
        } else if (props.label === 'Missed & Upcoming Due Dates') {
            setButtonDetails(props.button)
            setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, viewobj]);
        } else if (props.label === 'Manage GSTIN') {
            if (manageactive === 'Active') {
                setButtonDetails(props.button);
            } else {
                setButtonDetails(
                    props.button.filter((button: any) =>
                        // button.type !== 'upload' &&
                        // button.type !== 'add' &&
                        button.type !== 'syncall'
                    )
                );
            }
        }
        else {
            setButtonDetails(props.button)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tabledata, manageactive])

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);

    // search 
    const search = (value: any) => {
        if (noticeData[0].notice) {
            setNoticeObj(true);
        }
        const filterTable = noticeData.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setFilterList(filterTable);
    };

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;

        if (!value) {
            setSearchquery(value)
            setLocalsearch(value)
            search(value);
        } else {
            setLocalsearch(value)
        }

    }
    const searchclick = (value: any) => {
        if (props.label === 'Solution Dashboard') {
            search(value);
        } else {
            setPage(1)
            setSearchquery(value)
        }
    }

    useEffect(() => {
        if (globalsearch) {
            searchclick(globalsearch)
            setLocalsearch(globalsearch)
        } else {
            setSearchquery('')
            setLocalsearch('')
        }
    }, [globalsearch])
    // .............

    // filter
    useImperativeHandle(ref, () => ({
        setTableQuery(query: any) {
            console.log(query, 'tablequery check')
            tableListData.current = false;
            setPage(1)
            if (JSON.stringify(query) === '{}') {
                setFilterQuery({});
                // loadGNotices()
            } else {
                for (let key in query) {
                    setFilterQuery((existingValues: any) => ({
                        ...existingValues,
                        [key]: query[key],
                    }))
                }
            }
        },
    }))

    const handlecheck = (item: string, secFilter: any) => {
        if (secFilterCase === 'StateFilter') {

            if (checkedItems.includes(item)) {
                setCheckedItems(checkedItems.filter((checkedItem) => checkedItem !== item));
                setSecStateFilter(secStateFilter.filter((newItem: any) => newItem !== item));
                // secStateFilter.state = secStateFilter.state.filter((newItem: any) => newItem !== item)
            } else {
                setCheckedItems([...checkedItems, item]);
                setSecStateFilter([...secStateFilter, item])
                // secStateFilter.state = [...secStateFilter.state, item]
            }
            console.log("checkedItems", checkedItems, secStateFilter.state);

        } else if (secFilterCase === 'ArnFilter') {
            if (checkedArnItems.includes(item)) {
                setCheckedArnItems(checkedArnItems.filter((checkedArnItem) => checkedArnItem !== item));
                secStateFilter.arn_nos = secStateFilter.arn_nos.filter((newItem: any) => newItem !== item)
            } else {
                setCheckedArnItems([...checkedArnItems, item]);
                // secStateFilter.arm_nos = checkedArnItems.concat(',',item)
                secStateFilter.arn_nos = [...secStateFilter.arn_nos, item]
            }
            console.log("arn checked", checkedArnItems, secStateFilter.arn_nos);

        } else if (secFilterCase === 'CaseidFilter') {
            if (checkedCaseIdItems.includes(item)) {
                setCheckedCaseIdItems(checkedCaseIdItems.filter((checkedCaseIdItem) => checkedCaseIdItem !== item));
                secStateFilter.case_ids = secStateFilter.case_ids.filter((newItem: any) => newItem !== item)
            } else {
                setCheckedCaseIdItems([...checkedCaseIdItems, item]);
                // secStateFilter.case_ids = checkedCaseIdItems.concat(',',item)
                secStateFilter.case_ids = [...secStateFilter.case_ids, item]
            }
            console.log("caseid checked", checkedCaseIdItems, secStateFilter.case_ids);

        } else if (secFilterCase === 'SessionFilter') {
            if (checkedSessionItems.includes(item)) {
                setCheckedSessionItems(checkedSessionItems.filter((checkedSessionItem) => checkedSessionItem !== item));
                secStateFilter.section = secStateFilter.section.filter((newItem: any) => newItem)
            } else {
                setCheckedSessionItems([...checkedSessionItems, item]);
                // secStateFilter.section = checkedSessionItems.concat(',',item)
                secStateFilter.section = [...secStateFilter.section, item];
            }
            console.log("session checked", checkedSessionItems, secStateFilter.section);
        }
    }

    const secFilterChange = () => {
        setFilterQuery(secStateFilter);
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
        // Handle sorter state
        setSorterQuery(sorter);

        // Handle filter state
        // if (filters) {
        //     const isFilterCleared = Object.values(filters).every(
        //         (filter) => !filter || (Array.isArray(filter) && filter.length === 0)
        //     );

        //     if (isFilterCleared) {
        //         console.log('All filters cleared');
        //         setFilterQuery({}); // Reset the filters
        //     } else {
        //         setFilterQuery(filters); // Update with new filters
        //     }
        // }
        // const isFilterCleared = filters
        //     ? Object.values(filters).every(
        //         (filter) => !filter || (Array.isArray(filter) && filter.length === 0)
        //     )
        //     : false;

        // if (!isFilterCleared && filters) {
        //     // Update filter query if new filters are applied
        //     setFilterQuery(filters);
        // }

        setFilterQuery((prevFilters: any) => ({
            ...prevFilters,
            ...filters, // Merge new filters with existing ones
        }));

        // Handle pagination (if required)
        setPage(pagination.current || 1);
        setRowsPerPage(pagination.pageSize || rowsPerPage);
    };

    // const onChange: TableProps<any>['onChange'] = (filters, extra, sorter) => {
    //     // Handle sorter state
    //     tableListData.current = true;
    //     setSorterQuery(sorter);
    //     console.log(extra, 'console extra for check')
    //     // Handle case when filters are cleared
    //     const isFilterCleared = Object.values(extra).every(filter => !filter || filter.length === 0);
    //     if (isFilterCleared) {
    //         console.log('All filters cleared');
    //         setFilterQuery({});  // Clear the filter 
    //     } else {
    //         // Otherwise, update the filterQuery with the current filters
    //         setFilterQuery(extra); // Pass the filters, not 'extra', as filters contain the applied filters
    //     }
    // };

    // ..............

    // table action btns

    const btnClick = (data: any, query: any, btnfunction: any) => {
        if (data === 'get') {
            if (btnfunction) {
                btnfunction(selectedGST);
            }
        } else if (data === 'add') {
            if (btnfunction) {
                btnfunction();
            }
        } else if (data === 'view') {
            setChangeview(!changeview)
            setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, viewobj]);
            const updatedButtonDetails = buttondetails.map((button: any) => {
                if (button.type === 'view') {
                    return {
                        ...button,
                        name: changeview ? 'Calendar' : 'Table'
                    };
                }
                return button;
            });

            setButtonDetails(updatedButtonDetails);
        } else if (data === 'upload') {
            btnfunction();
        } else if (data === 'download' && !downloading) {
            setDownloading(true)
            if (props.label === 'Table of Contents') {
                const downloadquery = (() => {
                    switch (tabactive) {
                        case 'GSTIN Summary':
                            return 'get_group_notices';
                        case 'Additional':
                            return 'get_additinal_notices';
                        case 'Notices & Orders':
                            return 'get_notices_orders';
                        case 'Manual':
                            return 'get_manual_notices';
                        case 'Latest':
                            return 'get_latest_notices';
                        default:
                            return 'get_additinal_notices';
                    }
                })();
                downloadGST(downloadquery, setDownloading);

            } else {
                downloadGST(query, setDownloading);
            }
        } else if (data === 'status') {
            setStatuschange(true);
        } else if (data === 'viewchange') {
            setSelectedRowsArray([])
            setSorterQuery({})
            setFilterQuery({})
            setCheckedItems([]);
            setCheckedArnItems([]);
            setCheckedCaseIdItems([]);
            setCheckedSessionItems([]);
            setTableKey((prevKey: any) => prevKey + 1);
            setSearchquery('')
            setLocalsearch('')
            setExpanded(false)
            setSumdrawer(false);
            setTabview(!tabview);
            if (tabview) {
                setChartquery(false)
            }
            setTabactive(tabview ? 'GSTIN Summary' : 'Additional')
            setButtonDetails((prevButtonDetails: any) => {
                const filteredButtons = prevButtonDetails.filter((button: any) => (button.type !== 'notice') && (button.type !== 'sync') && (button.type !== 'status'));
                const updatedButtonDetails = filteredButtons.map((button: any) => {
                    if (button.type === 'viewchange') {
                        return {
                            ...button,
                            name: !tabview ? 'GSTIN summary' : 'All notices'
                        };
                    }
                    return button;
                });
                return updatedButtonDetails;
            });
        } else if (data === 'notice') {
            setAddnotice(true);
        } else if (data === 'assign') {
            btnfunction()
        } else if (data === 'delete') {
            if (btnfunction) {
                btnfunction(selectedGST)
            }
        } else if (data === 'latest') {
            if (btnfunction) {
                btnfunction()
            }
        } else if (data === 'sync' || 'syncall') {
            if (btnfunction) {
                btnfunction(selectedGST)
            }
        }

    }


    useEffect(() => {
        if (props.chartclick === true) {
            setTabview(!tabview);
            setTabactive(tabview ? 'GSTIN Summary' : 'Additional')
            setButtonDetails((prevButtonDetails: any) => {
                const filteredButtons = prevButtonDetails.filter((button: any) => (button.type !== 'notice') && (button.type !== 'status'));
                const updatedButtonDetails = filteredButtons.map((button: any) => {
                    if (button.type === 'viewchange') {
                        return {
                            ...button,
                            name: !tabview ? 'GSTIN summary' : 'All notices'
                        };
                    }
                    return button;
                });
                return updatedButtonDetails;
            });
        } else {
            setButtonDetails((prevButtonDetails: any) => {
                const filteredButtons = prevButtonDetails.filter((button: any) => (button.type !== 'notice') && (button.type !== 'status'));
                const updatedButtonDetails = filteredButtons.map((button: any) => {
                    if (button.type === 'viewchange') {
                        return {
                            ...button,
                            name: 'All notices'
                        };
                    }
                    return button;
                });
                return updatedButtonDetails;
            });
        }
    }, [props.chartclick])
    // ................

    // sync_now and sync_all button add and remove function
    const syncallButton = buttondetails.find((button: any) => button.type === 'syncall');

    let syncallFunction: (() => void) | undefined;


    if (syncallButton) {
        // If syncall object exists, extract its function
        syncallFunction = syncallButton.function as (() => void);
    }

    // sync option change button 
    const syncobj = {
        type: 'sync',
        name: 'Sync Now',
        theme: 'theme2',
        conditions: ['if_list'],
        function: syncallFunction
    }
    // ...........

    //notice status option change button 
    const statusobj = {
        type: 'status',
        name: 'Update status',
        theme: 'theme1',
        conditions: ['if_list'],
        function: () => setStatuschange(true)
    }
    // ...........

    // tab option change button 
    const tabobj = {
        type: 'viewchange',
        name: tabview ? 'GSTIN Summary' : 'All Notices',
        theme: 'theme1',
        conditions: ['if_list'],
        function: () => { setTabview(!tabview); setSorterQuery({}); setFilterQuery({}) }
    }
    // ...........

    // Add notice  button 
    const addnotice = {
        type: 'notice',
        name: 'Add Notice',
        theme: 'theme1',
        conditions: ['if_list'],
        function: () => setAddnotice(true)
    }
    // ...........


    // getsolution function	
    const createTickets = () => {
        ticket(selectedGstRows).then((data: any) => {
            setNotifyType("success")
            setNotifyMessage("Our experts team contact you shortly.")
            setNotifyOpen(true)
        }).catch((err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response.message)
            setNotifyOpen(true)
        })
    }
    // ...........

    // get solution button
    const getsolution = {
        type: 'get',
        name: 'Get Solution from Zen Tax Clinic',
        theme: 'theme2',
        conditions: ['if_list'],
        function: createTickets
    }
    // ...........

    // multi delete button
    const multi_delete = {
        type: 'delete',
        name: '',
        theme: 'theme4',
        conditions: ['if_list'],
        function: handleDelete
    }

    // Sync intiate function
    const syncchange = () => {
        console.log(tabactive, 'tab active change')
        if (props.label === 'Table of Contents') {
            if (tabactive === 'Additional') {
                const exists = buttondetails.some((button: any) => button.type === statusobj.type);
                if (!exists) {
                    setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, statusobj]);
                }
            }
            else if (tabactive === 'GSTIN Summary') {
                const multiDeleteExists = buttondetails.some((button: any) => button.type === multi_delete.type);
                if (!multiDeleteExists) {
                    setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, multi_delete]);
                }
            }
            else if (tabactive === 'All') {
                const multiDeleteExists = buttondetails.some((button: any) => button.type === multi_delete.type);
                if (!multiDeleteExists) {
                    setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, multi_delete]);
                }
            }
        }
        else if (props.label === 'Missed & Upcoming Due Dates') {
            const exists = buttondetails.some((button: any) => button.type === getsolution.type);
            if (!exists) {
                setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, getsolution]);
            }
        }
        else if (props.label === 'View Notice') {
            const addIfNotExists = (buttons: any[], newButton: any) => {
                const exists = buttons.some(button => button.type === newButton.type);
                if (!exists) {
                    return [...buttons, newButton];
                }
                return buttons;
            };

            // Usage
            setButtonDetails((prevButtonDetails: any) => addIfNotExists(prevButtonDetails, statusobj));
            setButtonDetails((prevButtonDetails: any) => addIfNotExists(prevButtonDetails, getsolution));
        }
        else if (props.label === 'PH Dashboard') {
            const exists = buttondetails.some((button: any) => button.type === statusobj.type);
            if (!exists) {
                setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, statusobj]);
            }
        }
        else if (props.label === 'Manage GSTIN') {
            if (manageactive === 'Active') {
                setButtonDetails(buttondetails.filter((button: any) => button.type !== 'syncall'));
                const syncObjExists = buttondetails.some((button: any) => button.type === syncobj.type);
                if (!syncObjExists) {
                    setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, syncobj]);
                }
                const multiDeleteExists = buttondetails.some((button: any) => button.type === multi_delete.type);
                if (!multiDeleteExists) {
                    setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, multi_delete]);
                }
            } else {
                const multiDeleteExists = buttondetails.some((button: any) => button.type === multi_delete.type);
                if (!multiDeleteExists) {
                    setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, multi_delete]);
                }
            }

        } else {
            // setButtonDetails(buttondetails.filter((button: any) => button.type !== 'syncall'));

            // const syncObjExists = buttondetails.some((button: any) => button.type === syncobj.type);
            // if (!syncObjExists) {
            //     setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, syncobj]);
            // }
            setButtonDetails(props.button)
        }
    }

    const syncall = () => {
        if (props.label === "Missed & Upcoming Due Dates") {
            setButtonDetails(props.button)
            setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, viewobj]);
        } else if (props.label === 'Table of Contents') {
            setButtonDetails(props.button)
            if (tabactive === 'Manual') {
                setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, addnotice]);
            }
        }
        else if (props.label === 'Manage GSTIN') {
            if (manageactive === 'Incorrect') {
                setButtonDetails(
                    buttondetails.filter((button: any) =>
                        // button.type !== 'upload' &&
                        // button.type !== 'add' &&
                        button.type !== 'syncall' &&
                        button.type !== 'delete'
                    )
                );
            } else {
                setButtonDetails(props.button);
            }
        }
        else {
            setButtonDetails(props.button)
        }
    }
    // ........

    // view change function (table/calendar)
    const viewobj = {
        type: 'view',
        name: changeview ? 'Table' : 'Calendar',
        theme: 'theme1',
        conditions: ['if_list'],
        function: () => setChangeview(!changeview)
    }

    const arrayOfObjects: any = noticeData;

    const generateSwitchCases = (datesArray: any): any => {
        const switchCases: any = {};
        if (props.label === 'Missed & Upcoming Due Dates') {
            datesArray.forEach((item: any) => {

                const formattedDate = moment(item.due_date).format('DD-MM-YYYY');
                if (formattedDate in switchCases) {
                    switchCases[formattedDate].push({ type: 'success', content: `${item.case_data.case_arn_no}`, gstid: `${item.id}` })
                } else {
                    switchCases[formattedDate] = [
                        { type: 'success', content: `${item.case_data.case_arn_no}`, gstid: `${item.id}` },
                    ];
                }
            });
        }

        const defaultCase = [
            { type: 'warn', content: 'No Due.' }
        ];

        switchCases.default = defaultCase;

        return switchCases;
    };

    const switchCases = generateSwitchCases(arrayOfObjects);

    const getListData = (value: any): any[] => {
        const date = new Date(value.$d); // Assuming value is of type DateObject
        const formattedDate = moment(date).format('DD-MM-YYYY');
        const listData = switchCases[formattedDate] || [];
        return listData;
    };


    const getMonthData = (value: Dayjs) => {
        if (value.month() === 8) {
            return 1394;
        }
    };

    const monthCellRender = (value: Dayjs) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };

    const navigate = useNavigate();
    const handleCellClick = (idvalue: any, value: any) => {
        const date = new Date(value.$d); // Assuming value is of type DateObject
        const formattedDate = moment(date).format('YYYY-MM-DD');
        navigate(`/home/${idvalue}/view-solution-details`)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }
        query['is_due'] = true;
        query['notice_start_due_date'] = formattedDate
        query['notice_end_due_date'] = formattedDate
        setNoticeQuery(query)
    };

    const dateCellRender = (value: Dayjs) => {
        const listData: any = getListData(value);
        const hasBadge = listData.length > 0;
        const count = listData.length;
        return (
            <>
                {hasBadge && (
                    <span onClick={() => handleCellClick(listData[0].gstid, value)}>
                        <Tooltip placement="topLeft" title={listData.map((item: any) => item.content).join(', ')} arrow={true} color={'#E96919'} >
                            <Badge size="default" count={count} style={{ backgroundColor: "#424B70", color: "#fff" }} />
                        </Tooltip>
                    </span>
                )}
            </>
        );
    };

    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
    };
    // ........

    // download gst function
    const downloadGST = async (query: any, loading: any) => {
        if (query === 'due_notices') {
            downloadServices.due_notices(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'get_case_summary') {
            downloadServices.get_case_summary(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, tabactive, loading);
        } else if (query === 'get_group_cases') {
            downloadServices.get_group_cases(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'manage_gst') {
            downloadServices.manage_gst(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'user_list') {
            downloadServices.users_list(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'sync_monitor') {
            downloadServices.sync_monitor(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'compliance_list') {
            downloadServices.compliance_list(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'get_group_notices') {
            downloadServices.get_group_notices(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, manageactive, loading);
        } else if (query === 'get_additinal_notices') {
            downloadServices.get_additinal_notices(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, JSON.stringify(noticeQuery) !== '{}' ? noticeQuery : null, loading);
        } else if (query === 'get_notices_orders') {
            downloadServices.get_notices_orders(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'get_manual_notices') {
            downloadServices.get_manual_notices(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'get_latest_notices') {
            downloadServices.get_latest_notices(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, JSON.stringify(noticeQuery) !== '{}' ? noticeQuery : null, loading);
        } else if (query === 'get_notices') {
            downloadServices.get_notices(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, loading);
        } else if (query === 'get_case_notices') {
            downloadServices.get_case_notices(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, JSON.stringify(sorterQuery) !== '{}' ? sorterQuery : null, JSON.stringify(searchquery) !== '{}' ? searchquery : null, JSON.stringify(noticeQuery) !== '{}' ? noticeQuery : null, loading);
        }
        else
            downloadServices.download_notices(null);
    }
    // ........

    const handletabClick = (label: any) => {
        setExpanded(false)
        setTabactive(label);
        setSumdrawer(false);
        setFilterQuery({})
        setSorterQuery({})
        setSearchquery('')
        setLocalsearch('')
        setSelectedRowsArray([])
        setCheckedItems([]);
        setTableKey((prevKey: any) => prevKey + 1);
        setCheckedArnItems([]);
        setCheckedCaseIdItems([]);
        setCheckedSessionItems([]);

        if (label === 'Notices & Orders') {
            setButtonDetails(buttondetails.filter((button: any) => (button.type !== 'notice') && (button.type !== 'status')));
        }
        else if (label === 'Manual') {
            setButtonDetails(buttondetails.filter((button: any) => button.type !== 'status'));
            const exists = buttondetails.some((button: any) => button.type === addnotice.type);
            if (!exists) {
                setButtonDetails((prevButtonDetails: any) => [...prevButtonDetails, addnotice]);
            }
        } else {
            setButtonDetails(buttondetails.filter((button: any) => (button.type !== 'notice') && (button.type !== 'status')));
        }
    }

    const handleviewtask = (record: any) => {
        setNoticedata(record)
        localStorage.setItem('noticedata', JSON.stringify(record))
        navigate(`/home/${record.id}/view-solution-details`);
        localStorage.setItem('taskCreatedDate', record.created_at)
    }

    const viewnotice_header_btn = [

        {
            id: 1,
            label: "Additional",
            query: 'Additional',
        },
        {
            id: 2,
            label: "Appeal",
            query: 'Appeal',
        }

    ]

    const enhanceColumns = (columns: any[], sorterQuery: any, userRole: string): any[] => {
        return columns.map((column: any) => {
            // If Super Admin, disable sorters and filters
            if (userRole === 'Super Admin') {
                return {
                    ...column,
                    sorter: false,
                    filters: undefined,
                    filterIcon: undefined,
                    filterSearch: false,
                    filterDropdown: undefined,
                };
            }

            // For non-Super Admin roles, add dynamic sorter highlighting
            if (column.sorter) {
                return {
                    ...column,
                    onHeaderCell: () => ({
                        className:
                            sorterQuery.columnKey === column.key
                                ? sorterQuery.order === 'ascend'
                                    ? 'highlight-sorter-ascend'
                                    : sorterQuery.order === 'descend'
                                        ? 'highlight-sorter-descend'
                                        : ''
                                : '',
                    }),
                };
            }

            return column; // Return unmodified column for non-sortable columns
        });
    };

    const enhancedColumns = enhanceColumns(props.columns, sorterQuery, userRole);

    return (
        <>
            {contextHolder}
            <Modal
                open={filter}
                onOk={() => { secFilterChange(); setFilter(false) }}
                onCancel={() => setFilter(false)}
                style={{ top: 0, left: 0, height: 220, width: 'max-content', position: "absolute" }} width={'20%'} >
                {secondaryFilter.map((item: any, index: any) => (
                    <div key={index}>
                        <Checkbox value={item} onChange={() => { handlecheck(item, secondaryFilter) }}
                            checked={checkedItems.includes(item) || checkedArnItems.includes(item) || checkedCaseIdItems.includes(item) || checkedSessionItems.includes(item)}
                        />
                        <span style={{ marginLeft: 10 }} >{item}</span>
                    </div>
                ))}
            </Modal>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div className='z-table' >
                    <div className="z-table-container">
                        <div className="z-left-mobile-container">
                            {!showsearch &&
                                <h2 className='z-gst-head'>
                                    {props.label === 'Table of Contents' || props.label === 'View Notice' || props.label === 'PH Dashboard' ? '' : props.label}
                                </h2>}
                            {!showsearch && < img
                                src={searchIcon}
                                className='mob-search-icon'
                                style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                alt='password icon'
                                onClick={() => setShowsearch(true)} />}
                            {showsearch && !changeview &&
                                <Input
                                    className='z-table_global_search'
                                    value={searchquery}
                                    onChange={handleGlobalSearchChange}
                                    placeholder='Type here to search...'
                                    suffix={<img
                                        onClick={() => setShowsearch(false)}
                                        src={searchIcon}
                                        style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                        alt='password icon' />}
                                />}
                        </div>
                        <div className="z-left-container">
                            {(location.pathname.split('/').pop() === 'overview') || (location.pathname.split('/').pop() === 'suboverview') && selectgst ?
                                <>
                                    <div className='value_block'>
                                        {selectgst?.trade_name
                                            ? `${selectgst?.trade_name.substring(0, 22)}...`
                                            : selectgst?.user_name || ''}
                                    </div>
                                    <div className='value_block'>
                                        {selectgst?.gst_no ? selectgst?.gst_no : selectgst?.password}
                                    </div>
                                    {formattedDate && <div className='value_block' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                                        <span><b style={{ color: '#E96919' }}>Last Sync :</b> {formattedDate}</span>
                                    </div>}
                                </>
                                : <>
                                    <h2 className='z-gst-head'>
                                        {props.label === 'Table of Contents' || props.label === 'View Notice' || props.label === 'Case Summary' || props.label === 'Missed & Upcoming Due Dates' || props.label === 'Manage GSTIN' ? '' : props.label}
                                    </h2>
                                    {props.label !== 'View Notice' && !changeview && <Input
                                        className='z-table_global_search'
                                        value={localsearch}
                                        onChange={handleGlobalSearchChange}
                                        placeholder='Type here to search...'
                                        suffix={<img
                                            src={searchIcon}
                                            onClick={() => searchclick(localsearch)}
                                            style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                            alt='password icon' />}
                                    />}
                                    {
                                        props.label === 'View Notice' &&
                                        <div className='view-notice-header-btn-wrap'>
                                            {viewnotice_header_btn.map((button: any) => (
                                                <Button
                                                    id="z-common-gst-solution-button"
                                                    className={button.query === viewnoticeacitve ? 'theme2' : 'theme1'}
                                                    onClick={() => setViewnoticeactive(button.query)}
                                                >
                                                    {button.label}
                                                </Button>
                                            ))}

                                        </div>
                                    }
                                </>}
                        </div>
                        <div className="z-right-container">

                            {buttondetails.map((button: any) => {
                                const isAdmin = userRole === 'Super Admin';
                                const isAllowedButton =
                                    !isAdmin ||
                                    (isAdmin &&
                                        (button.type !== 'sync' &&
                                            button.type !== 'syncall' &&
                                            button.type !== 'get' &&
                                            button.type !== 'add' &&
                                            button.type !== 'upload'));

                                const isConditionsValid =
                                    button.conditions?.length > 0 &&
                                    button.conditions.includes('if_list') &&
                                    (filterList && filterList.length === 0
                                        ? button.type !== 'sync' &&
                                        button.type !== 'syncall' &&
                                        button.type !== 'get' &&
                                        button.type !== 'status' &&
                                        button.type !== 'download'
                                        : button.type !== '');
                                // const isProcessSyncing = false;
                                const isProcessSyncing = button.conditions?.length > 1 ? button.conditions[1] : false;
                                const shouldRenderButton = isAllowedButton && (isConditionsValid || button.conditions?.length === 0);

                                return (shouldRenderButton && !isProcessSyncing) ? (
                                    <Tooltip
                                        placement="bottom"
                                        arrow={true}
                                        color='#424B70'
                                        title={
                                            button.type === 'get'
                                                ? 'Get Solution'
                                                : button.type === 'sync'
                                                    ? selectedGST.length === 0 && location.pathname.split('/').pop() !== 'overview' ? 'Sync All' : 'Sync Now'
                                                    : button.type === 'delete'
                                                        ? 'Delete'
                                                        : button.type === 'download'
                                                            ? downloading ? 'Downloading' : 'Download'
                                                            : ''
                                        }
                                    >
                                        <Button
                                            id="z-common-gst-solution-button"
                                            className={button.type === 'get' ? `${button.theme} get-btn` : button.theme}
                                            key={button.name}
                                            onClick={(e) => { btnClick(button.type, button.downloadquery, button.function) }}
                                        >
                                            {button.type === 'get' && <DoneIcon />}
                                            {button.type === 'download' && <img className="icon-btn" src={downloadIcon} alt='action' />}
                                            {button.type === 'add' && < img src={plusIcon} alt='plus icon' />}
                                            {button.type === 'notice' && < img src={plusIcon} alt='plus icon' />}
                                            {button.type === 'delete' && <DeleteIcon />}
                                            {button.type === 'status' && <EditIcon />}
                                            {button.type === 'sync' && <AutorenewIcon style={{ color: '#E96919', cursor: 'pointer' }} />}
                                            {button.type === 'upload' && <img className='icon-btn' src={uploadIcon} alt='upload' />}
                                            {button.type === 'syncall' && < img className="icon-btn" src={synctheme} alt='action' />}
                                            {button.type === 'view' && changeview && <TableViewIcon />}
                                            {button.type === 'view' && !changeview && <CalendarMonthIcon />}
                                            {button.type !== 'download' && button.type !== 'sync' && button.type !== 'get' && button.type !== 'delete' && (
                                                <span className={button.type === 'get' || button.type === 'viewchange' ? 'get-button' : 'button-label'}>
                                                    {button.type === 'get' && isMobile ? 'Get Solution' : button.name && button.type === 'viewchange' && isMobile ? (tabview ? 'Summary' : 'All') : button.name}
                                                </span>
                                            )}
                                        </Button>
                                    </Tooltip>
                                ) : isAdmin == false && isProcessSyncing ? (
                                    <Button
                                        id="z-common-gst-solution-button"
                                        className={button.type === 'get' ? `${button.theme} get-btn` : button.theme}
                                        key={button.name}
                                        onClick={(e) => setreportModal(true)}
                                    >
                                        < img className="icon-btn" src={viewSync} alt='action' />
                                        <span className={button.type === 'get' || button.type === 'viewchange' ? 'get-button' : 'button-label'}>
                                            View Progress
                                        </span>
                                    </Button>
                                ) : null;
                            })}
                            {props.label === 'Sync Monitoring' &&
                                < div className='sync_cnt_cover'>
                                    <div className='title_cover'>
                                        Sync :
                                    </div>
                                    <Tag color='success' style={{ borderRadius: "25px", padding: '7px 14px', fontSize: '2.2vmin' }} >Today - <b>{props.syncedCountsData.today}</b></Tag>
                                    <Tag color='success' style={{ borderRadius: "25px", padding: '7px 14px', fontSize: '2.2vmin' }} >Last <b>7</b> Days - <b>{props.syncedCountsData.week}</b></Tag>
                                    <Tag color='success' style={{ borderRadius: "25px", padding: '7px 14px', fontSize: '2.2vmin' }} >Last <b>30</b> Days - <b>{props.syncedCountsData.month}</b></Tag>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        (props.label === 'Table of Contents') && (
                            tabview &&
                            <div className="view-tab-cover-container">
                                <div className='view-tab-cover'>
                                    <span className={tabactive === 'Latest' ? 'view-tab one active-tab' : 'view-tab one'} onClick={() => {
                                        handletabClick('Latest');
                                        setSorterQuery({});
                                        setFilterQuery({});
                                        setSearchquery('');
                                        setLocalsearch('')
                                        setCheckedItems([]);
                                        setTableKey((prevKey: any) => prevKey + 1);
                                        setCheckedArnItems([]);
                                        setCheckedCaseIdItems([]);
                                        setCheckedSessionItems([]);
                                    }}>Latest Notices</span>
                                    <span className={tabactive === 'Additional' ? 'view-tab two active-tab' : 'view-tab one'} onClick={() => {
                                        handletabClick('Additional');
                                        setSorterQuery({});
                                        setFilterQuery({});
                                        setSearchquery('');
                                        setLocalsearch('')
                                        setCheckedItems([]);
                                        setTableKey((prevKey: any) => prevKey + 1);
                                        setCheckedArnItems([]);
                                        setCheckedCaseIdItems([]);
                                        setCheckedSessionItems([]);
                                    }}>Additional</span>
                                    <span className={tabactive === 'Notices & Orders' ? 'view-tab two active-tab' : 'view-tab two'} onClick={() => {
                                        handletabClick('Notices & Orders');
                                        setSorterQuery({});
                                        setFilterQuery({});
                                        setSearchquery('');
                                        setLocalsearch('')
                                        setCheckedItems([]);
                                        setTableKey((prevKey: any) => prevKey + 1);
                                        setCheckedArnItems([]);
                                        setCheckedCaseIdItems([]);
                                        setCheckedSessionItems([]);
                                    }}>Notices & Orders</span>
                                    {/* <span className={tabactive === 'Appeal' ? 'view-tab three active-tab' : 'view-tab three'} onClick={() => {handletabClick('Appeal'); setSorterQuery({}); setFilterQuery({}); setSearchquery('');}}>Appeal</span> */}
                                    <span className={tabactive === 'Manual' ? 'view-tab four active-tab' : 'view-tab four'} onClick={() => {
                                        handletabClick('Manual');
                                        setSorterQuery({});
                                        setFilterQuery({});
                                        setSearchquery('');
                                        setLocalsearch('')
                                        setCheckedItems([]);
                                        setTableKey((prevKey: any) => prevKey + 1);
                                        setCheckedArnItems([]);
                                        setCheckedCaseIdItems([]);
                                        setCheckedSessionItems([]);
                                    }}>Manual</span>
                                </div>
                            </div>
                        )
                    }
                    {/* {
                        (props.label === 'Manage GSTIN') && (
                            <div className="manage-tab-cover-container">
                                <div className='manage-tab-cover'>
                                    <span className={manageactive === 'Active' ? 'manage-tab one active-tab' : 'manage-tab'} onClick={() => { setManageactive('Active') }}>
                                        Active GSTIN
                                    </span>
                                    <span className={manageactive === 'Incorrect' ? 'manage-tab two active-tab' : 'manage-tab'} onClick={() => { setManageactive('Incorrect') }}>
                                        Incorrect GSTIN
                                    </span>
                                </div>
                            </div>
                        )
                    } */}
                    {changeview ?
                        <div className='z-gst-table-container'>
                            <Calendar
                                onSelect={(date: Dayjs | undefined) => setSelectedDate(date)}
                                value={selectedDate}
                                cellRender={cellRender}
                                style={{ padding: '10px' }}
                            />
                            {props.loading &&
                                <Spin spinning={props.loading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginLeft: '44.5vw', marginTop: "-70vh" }}>
                                </Spin>
                            }

                        </div> :
                        <div className='z-gst-table-container' style={{ position: 'relative' }}>
                            <Table
                                key={tableKey}
                                columns={enhancedColumns}
                                loading={props.loading}
                                dataSource={filterList}
                                expandable={{
                                    expandedRowRender: (record) =>
                                        props.expand ? (<>
                                            <div className='gst-row-filter'>
                                                {props.label === 'Solution Dashboard' && <div className="gst-row-item">
                                                    <b>Notice Date</b>
                                                    <span>{record.issue_date ? record.issue_date : '--'}</span>
                                                </div>}
                                                {/* <div className="gst-row-item">
                                                    <b>State</b>
                                                    <span>{record.gst ? record.gst.state : record.custom_fields.cf_state ? record.custom_fields.cf_state : '--'}</span>
                                                </div> */}
                                                {props.label === 'Solution Dashboard' && <div className="gst-row-item">
                                                    <b>Tax Period</b>
                                                    <span>{record?.custom_fields?.cf_tax_period ?? '--'}</span>
                                                </div>}
                                                {/* {props.label !== 'Solution Dashboard' && <div className="gst-row-item">
                                                    <b>Ref ID</b>
                                                    <span>
                                                        {record.case_data && record.case_data.case_ref_id && record.case_data.case_ref_id.length > 0 ? (
                                                            record.case_data.case_ref_id[record.case_data.case_ref_id.length - 1]
                                                        ) : (
                                                            record.custom_fields && record.custom_fields.cf_case_id ? (
                                                                record.custom_fields.cf_case_id
                                                            ) : (
                                                                '--'
                                                            )
                                                        )}
                                                        {record.case_data && record.case_data.case_ref_id && record.case_data.case_ref_id.length > 1 && (
                                                            <Tooltip title={record.case_data.case_ref_id.join(', ')}>
                                                                ({record.case_data.case_ref_id.length})
                                                            </Tooltip>
                                                        )}
                                                    </span> */}
                                                {/* <span>
                                                        {record.custom_fields && record.custom_fields.cf_gst_portal_status ? (
                                                            record.custom_fields.cf_gst_portal_status
                                                        ) : (
                                                            '--'
                                                        )}
                                                    </span> */}
                                                {/* </div>} */}
                                                <div className="gst-row-item">
                                                    <b>Case ID</b>
                                                    <span>
                                                        {record.case_data && record.case_data.case_arn_no
                                                            ? record.case_data.case_arn_no
                                                            : record.custom_fields && record.custom_fields.cf_case_arn
                                                                ? record.custom_fields.cf_case_arn
                                                                : '--'}
                                                    </span>
                                                </div>
                                                <div className="gst-row-item">
                                                    <b>GST Portal Status</b>
                                                    <span>
                                                        {record.case_data
                                                            ? record.case_data.portal_status
                                                            : record.custom_fields && record.custom_fields.cf_gst_portal_status
                                                                ? record.custom_fields.cf_gst_portal_status
                                                                : '--'}
                                                    </span>
                                                </div>
                                                {props.label === 'Solution Dashboard' && <div className="gst-row-item">
                                                    <b>Due date </b>
                                                    <span>{record?.custom_fields?.cf_due_date_to_reply_to_notice ?? '--'}</span>
                                                </div>}
                                                {props.label === 'Solution Dashboard' ? <div className="gst-row-item">
                                                    <b>Description</b>
                                                    <span>{record?.custom_fields?.description_text ?? '--'}</span>
                                                </div> :
                                                    <div className="gst-row-item">
                                                        <b>Description</b>
                                                        <span>  {record?.case_data?.case_description?.length > 0
                                                            ? record?.case_data?.case_description[record?.case_data?.case_description?.length - 1]
                                                            : '--'}
                                                            {record?.case_data?.case_description?.length > 1 && (
                                                                <Tooltip style={{ cursor: 'pointer' }} title={record?.case_data?.case_description?.join(', ')}>
                                                                    ({record?.case_data?.case_description?.length})
                                                                </Tooltip>
                                                            )}
                                                        </span>
                                                    </div>}

                                                <div className="gst-row-item">
                                                    <b>Section</b>
                                                    <span>{record.section ? record.section : record.custom_fields && record.custom_fields.cf_case_section ? record.custom_fields.cf_case_section : '--'}</span>
                                                </div>
                                                <div className="gst-row-item">
                                                    <b>Attachments</b>
                                                    <span>{record.attachments && record.attachments.length > 0 ? record.attachments.map((att: any) => att.docName).join(', ') : '--'}</span>
                                                </div>
                                            </div>
                                        </>) : null
                                    ,
                                    expandIcon: ({ expanded, onExpand, record }) =>
                                        props.expand ? (
                                            expanded ? (
                                                <img alt='expand icon' src={expandIcon1} onClick={e => onExpand(record, e)} style={{ cursor: "pointer" }} />
                                            ) : (
                                                <img alt='expang icon' src={expandIcon} onClick={e => onExpand(record, e)} style={{ cursor: "pointer" }} />
                                            )
                                        ) : null
                                }}
                                rowSelection={props.hideSelect ||
                                    ((userRole === 'Super Admin' && props.label === 'Manage GSTIN') ||
                                        (userRole === 'Super Admin' && props.label === 'Table of Contents') ||
                                        (userRole === 'Super Admin' && props.label === 'Missed & Upcoming Due Dates')) ?
                                    undefined : {
                                        type: 'checkbox',
                                        selectedRowKeys: selectedRowsArray,
                                        columnTitle: "Select",
                                        columnWidth: 40,
                                        renderCell: (checked, record, index, originNode) => {
                                            if (record.ticket_created && record.ticket_id && record.ticket_id !== 'None') {
                                                return (
                                                    <Button type="link" onClick={() => { handleviewtask(record) }}>
                                                        View Task
                                                    </Button>
                                                );
                                            } else {
                                                return originNode;
                                            }
                                        },
                                        onChange(selectedRowKeys, selectedRows, info) {
                                            console.log(selectedRows, 'selected rows')
                                            setSelectedGST(selectedRows);
                                            setSelectedGstRows(selectedRows);
                                            setSelectedRowsArray(selectedRowKeys);
                                            {
                                                props.expand === false && ['Solution Dashboard', 'Table of Contents'].includes(props.label) === false &&
                                                    <>
                                                        {selectedRows && syncchange()}
                                                        {selectedRows.length === 0 && syncall()}
                                                    </>
                                            }
                                            {
                                                props.expand === true && ['Table of Contents'].includes(props.label) === true &&
                                                    <>
                                                        {selectedRows && syncchange()}
                                                        {selectedRows.length === 0 && syncall()}
                                                    </>
                                            }
                                        },
                                        getCheckboxProps: (record: any) => {
                                            return (props.label === 'Table of Contents' || props.label === 'Missed & Upcoming Due Dates') ?
                                                { className: record.ticket_created && record.ticket_id && record.ticket_id !== 'None' ? 'tableSelectCheckboxOverride' : '' } : {
                                                    disabled: record.sync_status == 'Syncing' || props.syncprogress,
                                                    // disabled: record.wrong_password
                                                }
                                        }
                                    }}
                                pagination={props.label !== 'Solution Dashboard' && userRole !== 'Super Admin' ? {
                                    position: ['bottomRight'],
                                    pageSizeOptions: ['5', '10', '20', '50', '100'],
                                    total: props.totalpages,
                                    current: page,
                                    showTotal: (total, range) => `${isMobile ? '' : 'Showing'} ${range[0]} of ${total} ${isMobile ? '' : 'results'}`,
                                    pageSize: rowsPerPage,
                                    showSizeChanger: true,
                                    onChange: (page, pageSize) => {
                                        setPage(page)
                                        setRowsPerPage(pageSize)
                                    },
                                    onShowSizeChange(current, size) {
                                        setPage(current)
                                        setRowsPerPage(size)
                                    },
                                } : false}
                                rowKey={'id'}
                                onChange={onChange}
                                scroll={{ y: props.label === 'Solution Dashboard' ? 400 : 345 }}
                            />
                        </div>}
                </div >

            </div >
        </>
    )
})