import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../Statecontext';
import './breadcrumb.scss';
import { Utils } from '../../core/utils/utils';

const Breadcrumbs: React.FC = () => {
    const { breadcrumbPath } = useGlobalState();
    const { menuclick } = Utils();
    const navigate = useNavigate()
    // Split the breadcrumb path into segments and generate breadcrumb links
    const breadcrumbSegments = breadcrumbPath.split('/').filter((segment: any) => segment);

    return (
        <nav className="breadcrumb">
            <ul>
                {breadcrumbSegments.map((segment: any, index: any) => {
                    const path = `/${breadcrumbSegments.slice(0, index + 1).join('/')}`;
                    return (
                        <li key={path}>
                            {index === 0 ? (
                                <span
                                    className='last'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        navigate('/home/gst-dashboard');
                                        menuclick()
                                    }}>
                                    {segment}
                                </span>
                            ) : index < breadcrumbSegments.length - 1 ? (
                                <span>{segment}</span>
                            ) : (
                                <span
                                    className='last'>
                                    {segment}
                                </span>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav >
    );
};

export default Breadcrumbs;
