// react 
import React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
// ..........

// scss file
import './latestupdates.scss';
// ..........

// package components
import { Input, Spin } from 'antd';
// ..........

// child components
import { serviceConfig } from '../../core/services';
import { AppContext } from '../../core/utils/Context';
import { useGlobalState } from '../../Statecontext';
import { NotifyAlert } from '../../components/notify_alert';
import Breadcrumbs from '../../shared/Breadcrumb';
import { Searchdata } from '../../shared/latest_search';
// ..........

// icons
import ArticleIcon from '@mui/icons-material/Article';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import searchIcon from '../../assets/images/search.svg';
// ..........

export const Latestupdates = () => {

    // loading state
    const [articlesload, setArticlesload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [folderloading, setFolderloading] = useState(false)
    const [formloading, setFormloading] = useState(false);
    const [searchloading, setsearchloading] = useState(false)
    const [categoryloading, setCategoryloading] = useState(false);
    // ..........

    // data states
    const [updatesList, setUpdateslist] = useState<any>([])
    const [noticeData, setNoticeData] = useState<any>([])
    const [searchdetails, setSearchdetails] = useState<any>([])
    const [localsearch, setLocalsearch] = useState('')
    const [folders, setFolders] = useState([])
    const [articles, setArticles] = useState<any>([])
    const [category, setCategory] = useState<any>([])
    const [subtab, setSubtab] = useState(() => {
        const savedata = localStorage.getItem('updatetab');
        return savedata ? JSON.parse(savedata) :
            'Knowledge Bank'
    })
    const [selfolder, setFolder] = useState<any>({})
    const [selcategory, setSelcategory] = useState<any>({})
    const [selarticle, setSelarticle] = useState<any>({})
    // ..........

    // form values states
    const [noticeObj, setNoticeObj] = useState(false);
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    const { dashbordarticle, setDashbordarticle, setBreadcrumbPath, totalpages, setTotalpages, page, rowsPerPage, searchquery, setSearchquery, setPage } = useGlobalState();
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // ..........

    // reference states
    const idRef = useRef<any>(null);
    const abortControllerRef = useRef<AbortController | null>(null);
    // ..........

    // get call for details
    const get_updates = useCallback((value: any) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for the new request
        const controller = new AbortController();
        abortControllerRef.current = controller;
        const signal = controller.signal;

        let query: any = {}
        query['search'] = value ?? '';

        // if (searchquery) {
        //     query['search'] = searchquery ?? '';
        // }

        setsearchloading(true)
        serviceConfig.get("article_search", true, query, null, signal).then((data: any) => {
            if (!signal.aborted) {
                setsearchloading(false)
                setSearchdetails(data.results || data)
            }
        }, (err: any) => {
            if (!signal.aborted) {
                setsearchloading(false)
                setNotifyType("error")
                setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                setNotifyOpen(true)
            }
        })

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const get_category = () => {
        let query: any = {}
        setCategoryloading(true)
        serviceConfig.get("knowledge_category", true, query, null).then((data: any) => {
            setCategoryloading(false)
            setCategory(data.folders)
            console.log(dashbordarticle, 'dash_article check')
            setSelcategory(dashbordarticle?.category_name ? { name: dashbordarticle?.category_name } : data.folders[0])
            get_knowledge_category(dashbordarticle?.category_id || data.folders[0].id)
        }, (err: any) => {
            setCategoryloading(false)
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
        })
    }

    const get_knowledge_category = (id?: any) => {
        let query: any = {}
        setFolderloading(true)
        const idToUse = id || selcategory.id;
        console.log(dashbordarticle, 'dash_article check')
        serviceConfig.get("knowledge_category_folder", true, null, { id: idToUse }, false).then((data: any) => {
            setFolderloading(false)
            setFolders(data.folders)
            setFolder(data.folders[0])
            data.folders[0]?.id && get_article(dashbordarticle?.folder_id || data.folders[0].id)
        }, (err: any) => {
            setFolderloading(false)
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
        })
    }

    const get_article = (id?: any) => {
        setArticlesload(true)
        const idToUse = id || selfolder.id;
        serviceConfig.get("knowledge_category_article", true, null, { id: idToUse }, false)
            .then((data: any) => {
                setArticlesload(false)
                setArticles(data.articles)
                console.log(dashbordarticle, 'dash_article check')
                if (dashbordarticle?.id) {
                    setSelarticle(dashbordarticle)
                    // get_particular_article(dashbordarticle?.id)
                }
            }, (err: any) => {
                setArticlesload(false)
                setNotifyType("error")
                setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                setNotifyOpen(true)
            })
    }

    const get_particular_article = useCallback((id?: any) => {
        setLoading(true)
        const idToUse = id || selarticle.id;
        serviceConfig.get("knowledge_by_article", true, null, { id: idToUse }, false)
            .then((data: any) => {
                setLoading(false)
            }, (err: any) => {
                setLoading(false)
                setNotifyType("error")
                setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                setNotifyOpen(true)
            })
    }, [])

    const article_select = (value: any) => {
        const matchedCategory = category.find((folder: any) => folder.id === value.category_id);

        const get_articl = (id?: any) => {
            setArticlesload(true)
            const idToUse = id || selfolder.id;
            serviceConfig.get("knowledge_category_article", true, null, { id: idToUse }, false)
                .then((data: any) => {
                    setArticlesload(false)
                    setArticles(data.articles)
                    setSelarticle(value)
                }, (err: any) => {
                    setArticlesload(false)
                    setNotifyType("error")
                    setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                    setNotifyOpen(true)
                })
        }

        const get_category = (id?: any) => {
            setFolderloading(true)
            const idToUse = id || selcategory.id;
            serviceConfig.get("knowledge_category_folder", true, null, { id: idToUse }, false).then((data: any) => {
                setFolderloading(false)
                setFolders(data.folders)
                const matchedFolder = data.folders.find((folder: any) => folder.id === value.folder_id);
                setFolder(matchedFolder)
                data.folders[0]?.id && get_articl(value.folder_id)
            }, (err: any) => {
                setFolderloading(false)
                setNotifyType("error")
                setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                setNotifyOpen(true)
            })
        }

        if (matchedCategory) {
            setSelcategory(matchedCategory);
            get_category(value.category_id)
        }
    }

    useEffect(() => {
        get_category()
        setBreadcrumbPath(`home/services/latest updates`);
    }, [])

    useEffect(() => {
        setBreadcrumbPath(`home/services/latest updates/${selcategory?.name ?? ''}/${selfolder?.name ?? ''}`);
    }, [selfolder])

    useEffect(() => {
        if (Object.keys(dashbordarticle).length === 0 && idRef.current) {
            get_knowledge_category(idRef.current);
            idRef.current = null; // Clear the ref after calling the function
        }
    }, [dashbordarticle])

    // search 
    const search = (value: any) => {
        if (noticeData[0]?.notice) {
            setNoticeObj(true);
        }
        const filterTable = noticeData.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setUpdateslist(filterTable);
    };

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;

        if (!value) {
            setSearchquery(value)
            setLocalsearch(value)
            search(value);
        } else {
            setLocalsearch(value)
        }
        setAnchorEl(e.currentTarget)
        get_updates(value)

    }
    const searchclick = (value: any) => {
        setPage(1)
        setSearchquery(value)
        get_updates(value)
    }
    // ....

    // subcomponent 
    const header = () => {

        const subtabclick = (action: any) => {
            setBreadcrumbPath(`home/services/latest updates/${action?.name}`);
            setSelcategory(action)
            setFolder({})
            setArticles([])
            setFolders([])
            setSelarticle({});
            localStorage.removeItem('updatetab');
            idRef.current = action.id; 
            // get_knowledge_category(action.id)
        }
        return (
            <div className='latest_header'>
                <div className='first_row'>
                    <span>Latest Updates</span>
                    <div className='right_cover'>
                        <Input
                            className='search'
                            value={localsearch}
                            onChange={handleGlobalSearchChange}
                            placeholder='Type here to search...'
                            suffix={<img
                                src={searchIcon}
                                onClick={() => searchclick(localsearch)}
                                style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                alt='password icon' />}
                        />
                    </div>
                </div>
                <div className='second_row'>
                    {!categoryloading ? category.map((action: any) => (
                        <span
                            className={
                                selcategory.name === action.name ?
                                    'sub_action active' : 'sub_action '}
                            key={action}
                            onClick={() => {
                                setDashbordarticle({});
                                subtabclick(action);
                            }}>
                            <span># </span> {action.name}
                        </span>
                    )) :
                        <span className='sub_action active'>
                            <span># </span> Loading...
                        </span>
                    }
                </div>
            </div >
        )
    }

    const content = () => {
        const capitalizeFirstLetter = (str: string) => {
            if (!str) return '';
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        };
        const capitalizeSubstring = (str: string) => {
            if (!str) return '';
            const capitalized = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            return capitalized.length > 36 ? capitalized.substring(0, 36) + '...' : capitalized;
        };

        const sendfeedback = (value: any) => {
            let url = ''
            let query = ''
            if (value === 'yes') {
                url = 'article_feedback_like'
                query = 'thumbs_up'
            } else {
                url = 'article_feedback_dislike'
                query = 'thumbs_down'
            }

            const idToUse = selarticle.id;

            serviceConfig.post(url, true, { feedback_type: query }, null, { id: idToUse }, false).then(
                (data: any) => {
                    setNotifyType("success")
                    setNotifyMessage(`Feedback marked successfully !`)
                    setNotifyOpen(true)
                }, (err: any) => {
                    setNotifyType("error")
                    setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                    setNotifyOpen(true)
                    setFormloading(false)
                })
        }

        return (
            <div className='content_wrap'>
                <div className='blog_list_wrap'>
                    {folderloading ?
                        <Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        </Spin> :
                        folders?.length !== 0 ?
                            folders.map((folder: any) => (
                                <span className={selfolder?.name === folder.name ? 'blocks active' : 'blocks'}
                                    key={folder}
                                    onClick={() => {
                                        setSelarticle({})
                                        setDashbordarticle({})
                                        setFolder(folder);
                                        get_article(folder.id);
                                        setBreadcrumbPath(`home/services/latest updates/${selcategory.name}/${folder?.name}`);
                                    }}>
                                    <span># </span>{folder.name}
                                </span>
                            ))
                            :
                            <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No data</span>
                    }
                </div>

                <div className='content_gallery' style={{ width: Object.keys(selarticle).length > 0 ? '56%' : '82%' }}>

                    {Object.keys(selarticle).length > 0 ?
                        <div className='article_showcase'>
                            <div className='head_wrap'>
                                <span className='head_lines'>{selarticle.title}</span>
                                <span className='date_lines'>Updated on : {selarticle.updated_at}</span>
                            </div>
                            <div className='rel_content_wrap'>
                                <div className='chat-body-html html_style' dangerouslySetInnerHTML={{ __html: selarticle.description }} />
                            </div>
                            <div className='feedback_wrap'>
                                <span>Did you find it helpful?</span>
                                <ThumbUpOffAltIcon className='like_btn' onClick={() => { sendfeedback('yes') }} />Yes
                                <ThumbDownOffAltIcon className='dislike_btn' onClick={() => { sendfeedback('no') }} />No
                                {/* <span>Glad we could be helpful. Thanks for the feedback.</span> */}
                            </div>
                        </div> :
                        <>
                            {articlesload ?
                                <Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                </Spin> :
                                <>
                                    {articles?.length !== 0 ? articles?.map((item: any, index: any) => (
                                        <div className='content_row' key={item.subject}>
                                            <span className='content_subject'>
                                                <ArticleIcon className='icon' />
                                            </span>
                                            <span className='content_desc'
                                                onClick={() => {
                                                    get_particular_article(item.id)
                                                    setSelarticle(item)
                                                }}>{capitalizeFirstLetter(item.title)}
                                            </span>
                                        </div>)) :
                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No data</span>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                {Object.keys(selarticle).length > 0 &&
                    <div className='relevant_folders'>
                        <span className='head_lines'>Related Articles</span>
                        <div className='rel_content_wrap'>
                            {articlesload ?
                                <Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                </Spin> :
                                <>
                                    {articles?.length !== 0 ? articles?.map((item: any, index: any) => (
                                        <div className={item.id === selarticle.id ? 'content_row active' : 'content_row'} key={item.subject}>
                                            <span className='content_subject'>
                                                <ArticleIcon className='icon' />
                                            </span>
                                            <span className='content_desc'
                                                onClick={() => {
                                                    setDashbordarticle({})
                                                    get_particular_article(item.id)
                                                    setSelarticle(item)
                                                }}>
                                                {capitalizeSubstring(item.title)}
                                            </span>
                                        </div>)) :
                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No data</span>
                                    }
                                </>
                            }
                        </div>
                    </div>}
            </div >
        )
    }
    // .....

    return (
        <>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            <Searchdata anchorEl={anchorEl} setAnchorEl={setAnchorEl} searchdetails={searchdetails} searchloading={searchloading} Selatricle={article_select}></Searchdata>

            <div style={{ marginTop: '1%' }}>
                <Breadcrumbs />
            </div>
            <div className='latest_wrap'>
                {header()}
                {content()}
            </div>
            {/* <ZTable2 label={'Latest Updates'} button={buttondetails} columns={columns} expand={false} hideSelect={true} tabledata={updatesList} loading={loading} totalpages={totalpages} /> */}
        </>
    )
}